/**
 * @file Contains SBGL payments URL processing rules.
 *
 * Client-side validation is less exhaustive than on the backend, we just need
 * to make sure that the URL parameters contain all the expected fields.
 *
 * @see https://joi.dev/api
 */

import Joi, { ValidationError as JoiValidationError } from 'joi';
import { LocationQuery } from 'vue-router';
import { PaymentRequestDetails, PaymentType } from '.';
import { PaymentValidationError } from './errors';

// Match policy reference number to expected regex patten.
const refSchema = Joi.string().pattern(/^([0-9]{2})([A-Z0-9]+)([0-9]{2})$/);

// Must match 987XXX.YY00 format, where string starts with "987", contains a
// decimal number, and ends with an extra "00".
//
// Used for both renewal and debt payments.
const obfuscatedPriceSchema = Joi.string()
  .pattern(/^(987)\d+(\.)+(\d{2})+(00)$/)
  .min(9)
  .custom((value, { schema }): number => {
    // Return integer amount to avoid float rounding.
    const [pounds, pennies] = value
      .replace(/^987/, '') // remove the 987 from the start.
      .replace(/(00)$/, '') // remove the 00 from the end.
      .split('.')
      .map((number) => parseInt(number, 10));

    return pounds * 100 + pennies;
  });

export const osbalPaymentUrlSchema = Joi.object({
  ref: refSchema.required(),
  price: obfuscatedPriceSchema.required(),
  product: Joi.string().required(),
  terms: Joi.string().optional(),
  createdate: Joi.string().length(6).required(),
  vuln: Joi.boolean().truthy('1', 'Yes').falsy('0', 'No').optional(),
});

export const osdebtPaymentUrlSchema = Joi.object({
  ref: refSchema.required(),
  price: obfuscatedPriceSchema.required(),
  product: Joi.string().required(),
  createdate: Joi.string().length(6).required(),
  expiredate: Joi.string().length(6).required(),
  paid: Joi.boolean().truthy('1', 'Yes').falsy('0', 'No').optional(),
});

export const renewalPaymentUrlSchema = Joi.object({
  ref: refSchema.required(),
  price: obfuscatedPriceSchema.required(),
  insurer: Joi.string().required(),
  product: Joi.string().required(),
  terms: Joi.string().optional(),
  invitedate: Joi.string().length(6).required(),
  renewdate: Joi.string().length(6).required(),
});

export const liveChatPaymentUrlSchema = Joi.object({
  ref: refSchema.required(),
  price: Joi.number().integer().required(),
  product: Joi.string().required(),
  created_at: Joi.string().required(),
});

export const resumeStripePaymentUrlSchema = Joi.object({
  payment_intent: Joi.string(),
  payment_intent_client_secret: Joi.string(),
  redirect_status: Joi.string(),
});

export async function extractRenewalPaymentUrl(
  params: LocationQuery
): Promise<PaymentRequestDetails> {
  try {
    const valid = await renewalPaymentUrlSchema.validateAsync(params);

    const { price, ...fields } = valid;

    return {
      amount: price,
      paymentType: PaymentType.Renewal,
      fields,
    };
  } catch (error) {
    if (error instanceof JoiValidationError) {
      throw PaymentValidationError.fromJoiErrors(error);
    }

    throw error;
  }
}

export async function extractOsbalPaymentUrl(
  params: LocationQuery
): Promise<PaymentRequestDetails> {
  try {
    const valid = await osbalPaymentUrlSchema.validateAsync(params);
    const { price, ...fields } = valid;

    return {
      amount: price,
      paymentType: PaymentType.Osbal,
      fields,
    };
  } catch (error) {
    if (error instanceof JoiValidationError) {
      throw PaymentValidationError.fromJoiErrors(error);
    }

    throw error;
  }
}

export async function extractOsdebtPaymentUrl(
  params: LocationQuery
): Promise<PaymentRequestDetails> {
  try {
    const valid = await osdebtPaymentUrlSchema.validateAsync(params);
    const { price, ...fields } = valid;

    return {
      amount: price,
      paymentType: PaymentType.Osdebt,
      fields,
    };
  } catch (error) {
    if (error instanceof JoiValidationError) {
      throw PaymentValidationError.fromJoiErrors(error);
    }

    throw error;
  }
}

export async function extractLiveChatPaymentUrl(
  params: LocationQuery
): Promise<PaymentRequestDetails> {
  // Extract serialized JSON object.
  let extractedData;

  try {
    const packedData = typeof params.r === 'string' ? params.r : null;
    const jsonData = atob(packedData?.replace(/_/g, '/').replace(/-/g, '+'));
    extractedData = JSON.parse(jsonData);
  } catch (error) {
    // TODO: is not a typical validation error and does not have an error bag
    throw new PaymentValidationError({
      message: ['Could not extract data from URL'],
      error,
    });
  }

  // Validate extracted fields.
  try {
    const valid = await liveChatPaymentUrlSchema.validateAsync(extractedData);
    const { price, ...fields } = valid;

    return {
      amount: price,
      paymentType: PaymentType.LiveChat,
      fields,
    };
  } catch (error) {
    if (error instanceof JoiValidationError) {
      throw PaymentValidationError.fromJoiErrors(error);
    }

    throw error;
  }
}
