// import { library } from '@fortawesome/fontawesome-svg-core';
// import {
//   faCalendarAlt,
//   faPaste,
//   faPoundSign,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { i18n } from './locale';
import { router } from './router';
import { setupSentryVue } from './sentry';
import { stateDebugPlugin } from './stateDebugPlugin';

// Set up Vue...
const app = createApp(App);

// Set up Stripe browser SDK.
if (import.meta.env.MODE !== 'production') {
  setupSentryVue(app);
}

// Set up global head instance.
const head = createHead();
app.use(head);

// Set up SPA routing.
app.use(router);

// Set up internationalisation and brand specific text overrides.
app.use(i18n);

// // Add Font Awesome icons components.
// library.add(faCalendarAlt, faPoundSign, faPaste);
// app.component('FontAwesomeIcon', FontAwesomeIcon);

// Set up Pinia data stores.
const pinia = createPinia();
app.use(pinia);

// Set up XState debugging tools.
app.use(stateDebugPlugin);

app.mount('#app');
