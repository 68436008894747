import * as Sentry from '@sentry/vue';
import { usePaymentStore } from './stores/payment';

type redactorFormatter = (input: string) => string;

function redactVulnUrlParam(input: string) {
  const regex = /vuln\=([^&\s]+)/g;

  return input.replaceAll(regex, 'vuln=[vuln]');
}

// const depthKeys = [];

function deepRedact(
  depth: number,
  input: object,
  ...formatters: redactorFormatter[]
) {
  if (depth > 100) {
    // console.debug('Depth limit reached, skipping deeper properties');
    return;
  }

  Object.keys(input).forEach(function (k) {
    // if (!depthKeys[k]) {
    //   depthKeys[k] = 0;
    // }

    // depthKeys[k]++;

    if (input[k] !== null && typeof input[k] === 'object') {
      deepRedact(depth + 1, input[k], ...formatters);
      return;
    }

    if (typeof input[k] === 'string') {
      formatters.map((formatter) => {
        input[k] = formatter(input[k]);
      });
    }
  });
}

/**
 * Loop through matches of event JSON and print to console.
 */
function warnEventVulnData(event) {
  const regex = /vuln\=([^&\s\]\[]+)/g;

  let match;
  while ((match = regex.exec(JSON.stringify(event))) != null) {
    console.warn('Stripe event contains vulnerable customer flag');

    console.debug(match.input.substring(match.index - 80, match.index + 20));

    console.debug(event);
  }
}

/**
 * @see https://docs.sentry.io/platforms/javascript/guides/vue/
 */
export function setupSentryVue(app) {
  console.info('Setting up Sentry logging...');

  const { VITE_SENTRY_DSN, VITE_SENTRY_ENVIRONMENT } = import.meta.env;

  Sentry.init({
    app,
    dsn: VITE_SENTRY_DSN,
    environment: VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'somersetbridgelimited.co.uk',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        maskAllInputs: true,
      }),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Session Replay
    replaysSessionSampleRate: VITE_SENTRY_ENVIRONMENT === 'staging' ? 1.0 : 0.2,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // // Called for message and error events
    // beforeSend(event, _hint) {
    //   return event;
    // },

    // beforeSendTransaction(event, _hint) {
    //   return event
    // },

    beforeBreadcrumb(breadcrumb, _hint) {
      if (breadcrumb.category === 'console' && breadcrumb.level === 'debug') {
        return null;
      }

      return breadcrumb;
    },
  });

  Sentry.addEventProcessor((event, hint) => {
    deepRedact(0, event, redactVulnUrlParam);

    // console.debug(JSON.stringify({ event, hint }, null, 2));

    // console.debug(
    //   JSON.stringify(
    //     Object.entries(depthKeys).sort(([, a], [, b]) => b - a),
    //     null,
    //     2
    //   )
    // );

    // depthKeys.splice(0, depthKeys.length);

    warnEventVulnData(event);

    return event;
  });
}

/**
 * Send state machine errors to Sentry.
 */
export function setupSentryErrorCapture() {
  const { service } = usePaymentStore();

  service.onEvent((event) => {
    if (event.type === 'PAYMENT_ERROR' && event.error) {
      // Sentry.captureException(event.error);
    } else {
      Sentry.addBreadcrumb({
        category: 'state',
        message: `Payment state changed ${event.type}`,
        level: 'info',
      });
    }
  });
}
